import { Link } from "gatsby";
import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import SEO from "../../seo";
export default function Transportation() {
  return (
    <Layout>
      <SEO
        title="Transportation | Chinmaya Vidyalaya Vasant Vihar"
        description="The School runs an efficient transport system, with a safe and reliable transport facility extended to students."
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="transportation">
        <h1 className="heading">Transportation</h1>

        <p className="transportation__text">
          The School runs an extremely efficient transport system, with safe and
          reliable transport facility extended to students upon request at the
          beginning of the session, subject to availability of seats. Decision
          of school authorities regarding routes, timing and pickup/drop points
          are finalized in the beginning of the session, and no changes proposed
          by parent(s) would be considered during the session.
          <br />
          <br />
          Parents are advised to avoid the use of vans, run by private owners
          for the safety of their wards.
          <br />
          <br />
          Updated contact details for each school vehicle are available via the
          link below, for use in case of emergencies.
        </p>

        <div className="transportation__buttons">
          {/* <Link
            className="transportation__buttons__btn btn-dark"
            to="/bus-routes/"
          >
            Bus Routes
          </Link> */}
          <a
            target="_blank"
            className="transportation__buttons__btn btn-dark"
            href="https://chinmayavvdelhi.ac.inpn/assets/pdfs/Bus-Route-Details-2024-25.pdf"
          >
            Bus Routes
          </a>
          <a
            target="_blank"
            className="transportation__buttons__btn btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/Circle-wise-transport-fee-for-the-session-2024-25.pdf"
          >
            Bus Fee Structure
          </a>
          {/*  <a
            target="_blank"
            className="transportation__buttons__btn btn-dark"
            href="https://chinmayavvdelhi.ac.in/assets/pdfs/Details-of-Bus-Drivers-&-Helpers.pdf"
          >
            Contact Details
          </a> */}
        </div>

        <div className="transportation__section2">
          <h2 className="heading--white">Discipline in School Transport</h2>

          <div>
            Expected student behavior in school buses, and points of note for
            use of the school transport facility, are detailed below.
          </div>

          <ul>
            <li>
              No student shall deface or damage the bus in any way. Any damage
              done to the bus, shall have to be made good by the parents. School
              reserves the right to withdraw bus facility to the erring
              student(s).
            </li>

            <li>
              Any complaint or suggestions in writing must be handed over to the
              Transport ln-charge / Admin officer Parents / Guardians are
              expected not to enter into any argument with the bus driver or the
              conductor.
            </li>

            <li>
              No letters, applications, cheque and cash are to be sent to the
              school through the driver or the conductor of the bus.
            </li>

            <li>
              No student availing the transport facility should leave the school
              with In case, any student is found doing so, strict action will be
              taken, and bus facility will be discontinued.
            </li>

            <li>
              Parents are required to seek prior written approval for change of
              route for a day or always travel in your bus route and alight only
              at your bus stop.
            </li>

            <li>
              Every parent is expected to fill in the bus user or non-user
              application at the beginning of the session.
            </li>
          </ul>
        </div>

        <div className="transportation__section3">
          <h2 className="heading">Withdrawl of Bus Facilities</h2>

          <p>
            Application for withdrawal of bus facilities should reach the office
            by 10th of the month. After that fee for next month to be charged as
            notice period. No withdrawal of bus facilities for the month of
            January to March is allowed. The school is not responsible for the
            safety and security of private vans. It is the parent’s
            responsibility.
          </p>
        </div>
      </div>
    </Layout>
  );
}
